import  ApplicationController  from './application_controller'
import  ApexCharts             from 'apexcharts'

export default class extends ApplicationController {

  static targets = [ "chart" ]

  render(options) {
    if(this.hasChartTarget) {
      var chart = new ApexCharts(this.chartTarget, options);
      chart.render();
    }
  }
}
