import { driver } from "driver.js";
import { post } from "@rails/request.js"

import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  static targets = ["link", "button", "panel"]

  static values = {
    element: String,
    tip: Object,
    show: Boolean,
    user: String,
    tour: String
  }


  showNotificationsTip() {
    const driverObj = driver({
      onDestroyed: async () => {
        await post(`/product_tours/seen?product_tour_id=${this.tourValue}`, {
          body: JSON.stringify({ product_tour_view: { user_id: this.userValue, product_tour_id: this.tourValue }}),
          responseKind: "json"
        })
      }
    });

    if (this.showValue === true) {
      this.sleep(500) // sleep for 1/2 second
      driverObj.highlight({
        element: this.elementValue,
        popover: this.tipValue
      });
    }

  }

  showReleaseTip() {
    const driverObj = driver({
      onDestroyed: async () => {
        await post(`/product_tours/seen?product_tour_id=${this.tourValue}`, {
          body: JSON.stringify({ product_tour_view: { user_id: this.userValue, product_tour_id: this.tourValue }}),
          responseKind: "json"
        })
      }
    });

    if (this.showValue === true) {
      this.sleep(500) // sleep for 1/2 second
      driverObj.highlight({
        popover: this.tipValue
      });
    }
  }

  connect() {
    this.showNotificationsTip()
  }

  teardown() {
    this.showValue = false
    console.log(this.showValue)
    this.showNotificationsTip()
  }

  // showValueChanged() {
  //   if (this.showValue === false) {
  //     this.element.id = 'new-id-without-highlight' // hides the product notification
  //   }
  // }
}