import  ApplicationController  from './application_controller'
import { post } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ["content", "title", "down", "up"]

  static values = {
    track: Boolean,
    stayOpen: Boolean,
    objectId: String,
    userId: String,
    trackUrl: String
  }

  initialize() { }

  connect() {
    if (this.stayOpenValue) {
      this.show()
    }
  }

  show() {
    this.contentTarget.classList.remove('hidden')
    this.downTarget.classList.add('hidden')
    this.upTarget.classList.remove('hidden')
  }

  toggle(event) {
    const elementId = event.currentTarget.dataset.id

    if (elementId === 'show') {
      this.show()
      event.currentTarget.dataset.id = 'hide'

      this.trackOpens(elementId)
    } else {
      this.hide()
      event.currentTarget.dataset.id = 'show'
    }
  }

  hide() {
    this.contentTarget.classList.add('hidden')
    this.downTarget.classList.remove('hidden')
    this.upTarget.classList.add('hidden')
  }

  async trackOpens(eventId) {
    if (this.hasTrackValue && this.trackValue) {
      await post(`${this.trackUrlValue}`, {
        body: JSON.stringify({ view_history: { viewed_by_id: this.userIdValue }, viewable_id: this.objectIdValue }),
        responseKind: "html"
      })
    }
  }
}
