import ChartsController from '../charts_controller'

export default class extends ChartsController {

  static values = { chartType: { type: String, default: 'semiCircle' }, series: Array, labels: Array, size: { type: Number, default: 280 } }

  initialize() {
    let semiCircleOptions = {
      hollow: {
        size: '60%',
      },
      startAngle: -90,
      endAngle: 90,
      track: {
        background: '#C9DCF8',
        startAngle: -90,
        endAngle: 90,
      },
      dataLabels: {
        show: true,
        name: {
          show: false,
        },
        total: {
          show: false,
        },
        value: {
          fontSize: "30px",
          show: true
        }
      }
    } 

    let withInsideLabelOptions = {
      hollow: {
        size: '70%',
      },
    }

    this.options = {
      chart: {
        type: 'radialBar',
        height: this.sizeValue,
      },
      plotOptions: {
        radialBar: this.chartTypeValue == 'semiCircle' ? semiCircleOptions : withInsideLabelOptions,
      },
      stroke: {
        lineCap: "round"
      },
      legend: {
        position: 'top'
      },
      series: this.seriesValue,
      labels: this.labelsValue
    }
  }

  connect() { 
    this.render(this.options) 
  }
}
