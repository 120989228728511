import  ApplicationController  from './application_controller'
import { get, post } from '@rails/request.js'

export default class extends ApplicationController {

  static targets = ["buildingSelect"]
  static values = {
    filterUrl: String
  }

  filter(event) {
    let target = event.currentTarget.value

    get(`/admin/users?residence=${target}`, {
      responseKind: "turbo_stream"
    })
  }

  async search(event) {
    let target = event.currentTarget.value

    await get(`${this.filterUrlValue}?search=${target}`, {
      responseKind: "turbo_stream"
    })
  }

  changeResidence(event) {
    let organisation = event.target.selectedOptions[0].value
    let target = this.buildingSelectTarget.id

    get(`/admin/users/list_buildings?target=${target}&organisation_id=${organisation}`, {
      responseKind: 'turbo_stream'
    })
  }
}
