const spinner = (controller) => {
  Object.assign(controller, {
    initialize() {
      this.element.setAttribute("data-action", "click->form#click")
    },

    click(event) {
      this.element.value = 'Loading...'
      this.element.disabled = True
    },
  });
};

export default spinner
