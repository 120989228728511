import  ApplicationController  from './application_controller'
import Cleave from 'cleave.js';


export default class extends ApplicationController {

  static values = {
    fieldType: { type: Object, default: { alphanumeric: true, blocks: [3, 3, 4] } }
  }
  static targets = ["phoneNumber"]

  format(event) {
    if (this.hasPhoneNumberTarget) {
      var cleave = new Cleave(this.phoneNumberTarget, this.fieldTypeValue);
    }
  }

}
