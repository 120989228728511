import ApplicationController from "./application_controller";
import { post } from "@rails/request.js";

export default class extends ApplicationController {
  static targets = ["studentNumber", "errors"];

  static values = {
    teamUrl: String,
  };

  initialize() {}

  connect() {}

  async addStudent(event) {
    let id = event.currentTarget.dataset.user;

    await post(`${this.teamUrlValue}`, {
      body: JSON.stringify({ user_id: id }),
      responseKind: "html",
    });
  }
  validateStudentNumber() {
    if (this.hasStudentNumberTarget && this.hasErrorsTarget) {
      const student_number = this.studentNumberTarget.value;
      const valid = student_number.match(/^[A-Za-z]{6}\d{3}$/g);

      if (valid !== null) {
        this.errorsTarget.classList.add("hidden");
        this.errorsTarget.textContent = "Student number is valid";
        return true;
      } else {
        this.errorsTarget.classList.remove("hidden");
        this.errorsTarget.textContent = "Student number is invalid";
        this.errorsTarget.classList.add("text-danger-400");
        return false;
      }
    }
  }
}
