import  ApplicationController  from './application_controller'
import { get, post } from '@rails/request.js'
import collapse       from '../mixins/collapse'

export default class extends ApplicationController {
  static targets = ["query", "results", "toggler", "panel", "hiddenField", "team", "object"]

  static values  = {
    frequency: { type: Boolean, default: true },
    objectId: String,
    url: String,
    optionsTarget: String,
    target: String
  }

  initialize() { collapse(this) }

  connect() { collapse(this) }

  async liveSearch(event) {
    let q = event.currentTarget.value

    collapse(this)

    await get(`${this.urlValue}?q=${q}&target=${this.targetValue}`, {
      responseKind: "turbo_stream"
    })

  }

  async getStudentHost(event) {
    let q = event.currentTarget.value

    await get(`${this.urlValue}?q=${q}&target=${this.targetValue}`, {
      responseKind: "turbo_stream"
    })
  }

  async linkSearch(event) {
    event.preventDefault()
    let q = event.currentTarget.innerHTML

    await get(`${this.urlValue}?floor=${q}&target=${this.targetValue}`, {
      responseKind: "turbo_stream"
    })
  }

  async numBedroomsSearch(event) {
    event.preventDefault()
    let q = event.currentTarget.innerHTML

    await get(`${this.urlValue}?filter_bedrooms=${q}&target=${this.targetValue}`, {
      responseKind: "turbo_stream"
    })
  }


  async search(event) {
    let q = event.currentTarget.value

    await get(`${this.urlValue}?q=${q}&target=${this.targetValue}`, {
      responseKind: "turbo_stream"
    })
  }

  showOptions(event) {
    let q = event.target.value
    let target = this.optionsTargetValue


    get(`/bedrooms/search?q=${q}&target=${target}`, {
      responseKind: "turbo_stream"
    })
  }

  updateResults(data) {
    this.resultsTarget.classList.remove('hidden')
    const list = document.querySelector("#results")
    data.forEach((item) => {
      let tag = document.createElement('a')
      tag.innerHTML = `${item.std_num}`
      let div = document.createElement('div')
      div.innerHTML = `${item.full_name}`
      div.setAttribute('class', 'text-muted')
      tag.setAttribute('class', 'px-8 py-4 hover:bg-ghostWhite')
      tag.setAttribute('href', `/workspace/students/profile/${item.id}`)
      tag.setAttribute('data-turbo', 'true')
      tag.setAttribute('data-turbo-frame', 'workspace_student_profile')
      list.append(tag)
    })
  }
  showResults() {
    this.resultsTarget.classList.remove('hidden')
  }
  async trigger(event) {
    this.queryTarget.value = event.currentTarget.innerHTML
    if (this.hasHiddenFieldTarget) {
      const hasUserId = event.currentTarget.dataset.userId
      if (hasUserId) {
        this.hiddenFieldTarget.value = event.currentTarget.dataset.userId
      } else {
        this.hiddenFieldTarget.value = event.currentTarget.dataset.value
      }
    }

    this.resultsTarget.classList.add('hidden')
    this.panelTarget.classList.add('hidden')

    if (this.frequencyValue) {
      await get(`${this.urlValue}?q=${this.queryTarget.value}&target=${this.targetValue}`, {
        responseKind: "turbo_stream"
      })
    }
  }

  async submitTeamForm(event) {
    let user_id = event.currentTarget.dataset.userId

    await post(`/workspace/teams/${this.objectIdValue}/add_member`, {
      body: JSON.stringify({ user_id: user_id}),
      responseKind: 'turbo_stream'
    })
    this.resultsTarget.classList.add('hidden')
    this.panelTarget.classList.add('hidden')
    //const form = document.querySelector("#teamForm")
    //form.requestSubmit()
  }
  async filter(event) {
    let q = event.currentTarget.value
    let paramsName = event.currentTarget.dataset.name
    let toggleOptions = event.currentTarget.dataset.options

    if (paramsName === undefined) {
      paramsName = 'q'
    }

    if (toggleOptions) {
      if (event.currentTarget.checked) {
        q = event.currentTarget.value
      } else (
        q = ''
      )
    }

    await get(`${this.urlValue}?${paramsName}=${q}&target=${this.targetValue}`, {
      responseKind: "turbo_stream"
    })
  }
}
