import  ApplicationController  from './application_controller'

export default class extends ApplicationController {

  initialize() {
    this.hide()
  }

  connect() {
    setTimeout(() => {
      this.show()
    }, 200)
  }

  close() {
    this.hide()
    setTimeout(() => {
      this.element.remove()
    }, 1100)
  }

  show() {
    this.element.setAttribute(
      'style',
      "transition: 1s; transform:translate(0, 0);",
    )
    setTimeout(() => {
      this.hide()
    }, 2100)
  }

  hide() {
    this.element.setAttribute(
      'style',
      "transition: 1s; display: none;",
    )
  }
}
