import  ApplicationController  from './application_controller'

export default class extends ApplicationController {

  static values = {
    name: String,
    eventObject: Object
  }

  initialize() {
  }

  track() { 
    if (this.hasNameValue) {
    }
  }

  trackFromController({ detail: { content } }) {
    console.log('hello')
    console.log(content)
  }

 }
