import ApplicationController from '../../javascript/controllers/application_controller'
import { enter, leave } from "el-transition"
import { get, post } from '@rails/request.js'

export default class extends ApplicationController {

  static targets = ['checkbox', 'mainCheckbox', 'actions', 'totalItems', 'selected', 'panel']
  static classes = ['active', 'inactive']

  static values = {
    tableId: String,
    filterUrl: String,
    turboFrameId: String,
    columns: Array,
    paginationUrl: String
  }

  initialize() {
    let customHiddenColumns = window.localStorage.getItem(`${this.tableIdValue}-hiddenColumns`);

    this.hiddenColumns = customHiddenColumns ? JSON.parse(customHiddenColumns) : []; 
  }

  connect() {
    this.itemsCount = 0
    this.hideHiddenColumns()
  }

  async toggleCustomDate(event) {
    event.preventDefault();
    const element = event.currentTarget;
    let panel = document.getElementById("custom-range-panel")
    panel.classList.toggle("hidden")
  }
    
  updateCustomDate(event) {
    event.preventDefault();
    const element = event.currentTarget;
    let inputs = element.parentNode.querySelectorAll("input")
    let durationStart = inputs[0].value
    let durationEnd = inputs[1].value
    
    this.filterByCustomDate(event, durationStart, durationEnd)

    let panel = document.getElementById("custom-range-panel")
    panel.classList.toggle("hidden")

  }

  async filterByCustomDate(event, start, end) {
    event.preventDefault();
    await get(`${this.filterUrlValue}?target=${this.turboFrameIdValue}&start=${start}&end=${end}&custom=true&duration=Custom`, {
      responseKind: 'turbo-stream'
    })
  }
  async filterByDate(event) {
    event.preventDefault();
    const element = event.currentTarget;

    const durationStart = element.dataset.durationStart
    const durationEnd = element.dataset.durationEnd

    await get(`${this.filterUrlValue}?target=${this.turboFrameIdValue}&start=${durationStart}&end=${durationEnd}&duration=${element.text}`, {
      responseKind: 'turbo-stream'
    })
  }

  selectAll(event) {
    const element = event.currentTarget;

    // select all other checkboxes
    this.checkboxTargets.forEach((el) => {
      let rowElement = el.parentNode.parentElement
      const rowActiveClass = ['bg-primary-50']
      const rowInActiveClass = ['bg-primary-50']
      const selectedIndicator = el.parentElement.childNodes[3]

      if (element.checked) {
        el.checked = true
        rowElement.classList.add(...rowActiveClass)
        selectedIndicator.classList.toggle('hidden')

      } else {
        el.checked = false
        rowElement.classList.remove(...rowInActiveClass)
        selectedIndicator.classList.toggle('hidden')
      }
    })

    // show the actions 
    this.items = new Array()
    if (element.checked) {
      this.actionsTarget.classList.remove('hidden')
    } else {
      this.actionsTarget.classList.add('hidden')
      leave(this.actionsTarget)
    }
    this.totalItemsTarget.innerHTML = `${this.checkboxTargets.length} items selected`
  }

  select(event) {
    const element = event.currentTarget;

    const selectedIndicator = element.parentElement.childNodes[3]
    if (element.checked) {
      this.itemsCount += 1
      this.totalItemsTarget.innerHTML = `${this.pluralize('item', this.itemsCount, 's')} selected`
      selectedIndicator.classList.toggle('hidden')

    } else if (!element.checked) {
      this.itemsCount -= 1
      this.totalItemsTarget.innerHTML = `${this.pluralize('item', this.itemsCount, 's')} selected`
      selectedIndicator.classList.toggle('hidden')

      if (this.itemsCount < 1) {
        this.actionsTarget.classList.add('hidden')
      }
    }
    this.mainCheckboxTarget.checked = false
    this.actionsTarget.classList.remove('hidden')
    enter(this.actionsTarget)
  }

  async paginate(event) {
    event.preventDefault()
    console.log(event.currentTarget)
    const element = event.currentTarget

    await get(element.href, {
      responseKind: 'turbo_stream'
    }) 
  }

  // toggle download options
  change(event) {
    const element = event.currentTarget.parentNode.childNodes[3]

    this.panelTargets.forEach((el) => {
      if (el !== element) {
        el.classList.add('hidden')
      }
    })

    element.classList.toggle('hidden')
  }


  hideHiddenColumns() {
    this.hiddenColumns.forEach((columnName) => {
      // find the container
      const container = document.getElementById(this.tableIdValue)
      const elements = container.querySelectorAll(`#${columnName}`)

      elements.forEach((element) => {
        if (element.classList.contains('xl:table-cell') || element.classList.contains('md:table-cell')) {
          element.classList.remove('hidden')
          element.classList.remove('xl:table-cell')
          element.classList.remove('md:table-cell')
        }

        if (!element.classList.contains('hidden')) {
          element.classList.add('hidden')
        }
      })
    })

    // toggle checkboxes
    let checkboxes = document.querySelectorAll(`.toggleColumn${this.tableIdValue}`);
    checkboxes.forEach((checkbox) => {
      if (this.hiddenColumns.includes(checkbox.value)) {
        checkbox.checked = false
      }
    })
  }

  showColumn(columnName) {
    const container = document.getElementById(this.tableIdValue)
    const elements = container.querySelectorAll(`#${columnName}`)

    elements.forEach((element) => {
      element.classList.remove('hidden')
      element.classList.remove('md:table-cell')
    })
  }

  toggleColumns(event) {
    event.preventDefault()
    const columnName = event.currentTarget.value;

    if (event.currentTarget.checked) {
      let index = this.hiddenColumns.indexOf(columnName)
      if (index > -1) { // only splice array when item is found
        this.hiddenColumns.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.showColumn(columnName)
    } else {
      this.hiddenColumns.push(columnName)
    }

    window.localStorage.setItem(`${this.tableIdValue}-hiddenColumns`, JSON.stringify(this.hiddenColumns));
    this.hideHiddenColumns()
  }

  async download(event) {
    event.preventDefault()

    var ids = []
    var downloadableType = this.checkboxTargets[0].dataset.downloadable
    this.checkboxTargets.forEach((el) => {
      if (el.checked) {
        ids.push(el.dataset.id)
      }
    })

    await post('/workspace/downloads', {
      body: JSON.stringify({ download: { downloadable_type: downloadableType, ids: ids } }),
      responseKind: "html"
    })
  }

}
