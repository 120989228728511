import ApplicationController from "./application_controller";
import { inView, animate } from "motion"

export default class extends ApplicationController {

  static values = {
    quantity: Number
  }
  static targets = ["stat", "panel"]

  connect() {
    // this.scroll()
    this.animateStats()
  }

  scroll() {
    if (this.hasPanelTarget) {
      inView(this.panelTarget.querySelector("h2"), (info) => {
        animate(info.target, { opacity: 1 })
      })
      scroll(animate(".progress", { scaleX: [0, 1] }));
      scroll(animate(this.panelTarget.querySelector("h2"), { y: [-400, 400] }), {
        target: this.panelTarget.querySelector("h2")
      });
      inView(this.panelTarget, ({ target }) => {
        animate(
          target.querySelector("p"),
          { opacity: 1, transform: "none" },
          { delay: 0.2, duration: 1.9, easing: [0.17, 0.55, 0.55, 1] }
        )
      })
    }
  }

  animateStats() {
    if (this.hasStatTarget) {
      setTimeout(() => {
        animate(
          (progress) => this.statTarget.innerHTML = Math.round(progress * this.quantityValue),
          { duration: 2, easing: "ease-out" }
        )
      }, 1000)
    //   const formattedQuantity = Intl.NumberFormat('en', { notation: 'compact' }).format(parseInt(this.quantityValue, 10))
    //   this.statTarget.innerHTML = formattedQuantity
    }
  }
}