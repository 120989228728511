import ApplicationController  from './application_controller'

export default class extends ApplicationController {
  static targets = [ "toggler", "panel"]

  static values = { showLinks: Boolean }

  initialize() { }

  connect() { 
    this.showLinks()
  }

  change(event) {
    event.preventDefault();

    if (this.hasTogglerTarget && this.hasPanelTarget) {
      if (this.panelTarget.classList.contains('hidden')) {
        this.panelTarget.classList.remove('hidden');
      } else {
        if (this.hasShowLinksValue && !this.showLinksValue) {
          this.panelTarget.classList.remove('hidden');
        } else {
          this.panelTarget.classList.add('hidden');
        }
      }
    }
  }

  showLinks() {
    if (this.hasShowLinksValue) {
      if (this.showLinksValue && this.panelTarget.classList.contains('hidden')) {
        this.panelTarget.classList.remove('hidden');
      }
    }
  }

}
