import ChartsController from '../charts_controller'

export default class extends ChartsController {

  static values = { xType: String, series: Array, labels: Array }

  initialize() {
    this.options = {
      chart: {
        height: 380,
        width: "100%",
        //stackType: '100%'
      },
      stroke: {
        curve: 'smooth',
        width: [2,2]
      },
      series: this.seriesValue,
      xaxis: {
        type: this.xTypeValue
      }
    }
  }

  connect() { this.render(this.options) }
}
