import ChartsController from '../charts_controller'

export default class extends ChartsController {

  static values = {
    chartType: { type: Boolean, default: false },
    series: Array,
    labels: Array
  }

  initialize() {
    this.options = {
      chart: {
        height: 380,
        width: "100%",
        type: 'bar',
        stacked: false,
        //stackType: '100%'
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          dataLabels: { enabled: false, offsetY: -10 },
          horizontal: this.chartTypeValue,
          endingShape: 'rounded'
        }
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      series: this.seriesValue,
      xaxis: {
        categories: this.labelsValue
      },
      yaxis: {
        title: {
          text: 'Number of issues'
        }
      }
    }
  }

  connect() { this.render(this.options) }
}
