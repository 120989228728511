window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

gtag('js', new Date());

document.addEventListener("turbo:load", function(event) {

  gtag('config', 'G-92TRR1Y1P8', {
    page_location: event.detail.url,
  });
  
  gtag('config', 'GTM-NPPDHN5', {
    page_location: event.detail.url,
  });
})

export default gtag
