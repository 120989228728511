const studentNumberValidator = (controller) => {
  Object.assign(controller, {
    connect() { this.validate() },

    validate() {
      if (this.hasStudentNumberTarget && this.hasErrorsTarget && this.hasSubmitTarget) {
        const student_number = this.studentNumberTarget.value
        const valid = student_number.match(/^[A-Za-z]{6}\d{3}$/g)
        if ( valid != null) {
          this.studentNumberTarget.classList.add('focus:ring-green-500')
          this.errorsTarget.classList.add('hidden')
          this.submitTarget.classList.remove('hidden')
          this.errorsTarget.textContent = 'Student number is valid'
          return true;
        }
        this.studentNumberTarget.classList.add('focus:ring-red-500')
        this.errorsTarget.classList.remove('hidden')
        this.submitTarget.classList.add('hidden')
        this.errorsTarget.textContent = 'Student number is invalid'
        return false;
      }
    },
  });
};

export default studentNumberValidator
