import ApplicationController  from './application_controller'

export default class extends ApplicationController {
  static targets = [ "button", "form", "completed", "priorityForm"]

  initialize() { this.formHide() }

  connect() { this.formHide() }

  formHide() {
    if (this.hasFormTarget && !(this.formTarget.classList.contains('hidden'))) {
      this.formTarget.classList.add('hidden')
    }
  }

  formShow(event) {
    event.preventDefault()

    if ((this.formTarget.classList.contains('hidden'))) {
      this.formTarget.classList.remove('hidden')
      if (!(this.priorityFormTarget.classList.contains('hidden'))) {
        this.priorityFormTarget.classList.add('hidden')
      }
    } else {
        this.formHide()
    }
  }

  priorityFormShow(event) {
    event.preventDefault()

    if (this.priorityFormTarget.classList.contains('hidden')) {
      this.priorityFormTarget.classList.remove('hidden')
      if (!(this.formTarget.classList.contains('hidden'))) {
        this.formTarget.classList.add('hidden')
      }
    } else {
      this.priorityFormTarget.classList.add('hidden')
    }
  }

  showCompletedByField(event) {
    const selectedValue = event.currentTarget.value

    if (selectedValue === 'other') {
      this.completedTarget.classList.remove('hidden')
    } else {
      this.completedTarget.classList.add('hidden')
    }
  }

}

