import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["label", "svg", "panel", "button"];
  static classes = ["visible"];

  showCheckSVG(event) {
    const el = event.currentTarget;

    this.svgTargets.forEach((svg, index) => {
      if (index === parseInt(el.dataset.stamp)) {
        svg.classList.remove("hidden");
      } else {
        svg.classList.add("hidden");
      }
    });
  }

  check(event) {
    const el = event.currentTarget;

    this.labelTargets.forEach((label, index) => {
      if (el.checked && index === parseInt(el.dataset.stamp)) {
        label.classList.add("ring-primary-900");
      } else {
        label.classList.remove("ring-primary-900");
      }
    });
  }

  toggleSelectOptions(event) {
    if (this.hasPanelTarget) {
      this.panelTarget.classList.toggle(this.visibleClass);
    }
  }

  acknowledgeUpload(event) {
    const input = event.currentTarget;

    // Bind to normal file selection
    Array.from(input.files).forEach(file => uploadFile(file))
    // you might clear the selected files from the input
    input.value = null
  }
}
