import  ApplicationController  from './application_controller'

export default class extends ApplicationController {
  static targets = ["studentForm", "staffForm"]

  initialize() { }

  show(event) {
    if (this.hasStudentFormTarget && this.hasStaffFormTarget) {
      if (event.currentTarget.value === 'student_email') {
        this.studentFormTarget.classList.remove('hidden')
      } else if (event.currentTarget.value === 'staff_email') {
        this.staffFormTarget.classList.remove('hidden')
      }

    }
  }
}

