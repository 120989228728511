import ApplicationController  from './application_controller'

export default class extends ApplicationController {

  static targets = [
    "parcel", "parcelForm",
    "maintenance", "maintenanceForm",
    "laundry", "laundryForm",
    "other", "otherForm"
  ]

  initialize() { }

  show(event) {
    const t = event.target.value
    const target = `${t.toLowerCase()}Form`

    if (target === 'laundryForm' && this.hasLaundryFormTarget) {
      this.laundryFormTarget.classList.remove('hidden')
      this.maintenanceFormTarget.classList.add('hidden')
      this.parcelFormTarget.classList.add('hidden')

    } else if (target === 'maintenanceForm' && this.hasMaintenanceFormTarget) {
      this.maintenanceFormTarget.classList.remove('hidden')
      this.laundryFormTarget.classList.add('hidden')
      this.parcelFormTarget.classList.add('hidden')

    } else if (target === 'parcelForm') {
      this.laundryFormTarget.classList.add('hidden')
      this.maintenanceFormTarget.classList.add('hidden')

    } else if (target === 'otherForm') {
      this.laundryFormTarget.classList.add('hidden')
      this.maintenanceFormTarget.classList.add('hidden')
      this.otherFormTarget.classList.remove('hidden')
    }
  }
}
