import ApplicationController  from './application_controller'

export default class extends ApplicationController {

  static targets = ["slide"]
  static values = { index: Number }

  initialize() {
    this.indexValue = 0
  }

  connect() {
    this.playSlides()
  }

  wraparound(index) {
    if (index >= this.slideTargets.length) {
        return (index % (this.slideTargets.length))
    } else if (index < 0) {
        return Math.abs(index % (this.slideTargets.length))
    } else {
        return index
    }
  }

  next() {
    let value = this.indexValue + 1
    this.indexValue = this.wraparound(value)
  }

  prev() {
    this.indexValue--
  }

  indexValueChanged() {
    this.showCurrentSlide()
  }

  showCurrentSlide() {
    this.slideTargets.forEach((slide, index) => {
      if (index === this.indexValue) {
        slide.classList.add('slide-in')
        slide.classList.remove('hidden')
      } else {
        slide.classList.add('hidden')
        slide.classList.add('slide-out')
      }
    })
  }

  playSlides() {
    setInterval(() => {
      this.wraparound(this.next())
    }, 2500)
  }

}
