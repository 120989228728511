import ApplicationController  from './application_controller'

import { Calendar } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';

export default class extends ApplicationController {

  static targets = ["calendar"]

  static values = {
    events: Array,
    defaultView: String,
    displayMonthView: { type: Boolean, default: false },
    editable: { type: Boolean, default: false }
  }

  connect() {
    if (this.hasCalendarTarget) {
      const calendar = new Calendar(this.calendarTarget, {
        timeZone: 'Africa/Johannesburg',
        plugins: [
          interactionPlugin,
          timeGridPlugin,
          dayGridPlugin,
          listPlugin
        ],
        initialView: this.mapDefaultView(),
        nowIndicator: true,
        displayEventEnd: true,
        headerToolbar: {
            left: 'prev,next',
            center: 'title',
            right: this.availablePluginsForView() // user can switch between the two
        },
        editable: this.editableValue,
        events: this.eventsValue,
        views: {
            timeGridFourDay: {
              type: 'timeGrid',
              duration: { days: 4 }
            }
        },
        dateClick: () => {
        },
      })
  
      calendar.render()
    }
  }

  mapDefaultView() {
    if (this.hasDefaultViewValue) {
      if (this.defaultViewValue === 'day') return 'timeGridDay'
      else if (this.defaultViewValue === 'list') return 'listWeek'
      return 'timeGridWeek'
    } else {
      return 'timeGridDay'
    }
  }

  availablePluginsForView() {
    let views = 'timeGridWeek,timeGridDay'

    if (this.hasDisplayMonthViewValue && this.displayMonthViewValue) {
      views += ',dayGridMonth'
    }

    return views
  }

}