import  ApplicationController  from './application_controller'
import { get } from "@rails/request.js"

export default class extends ApplicationController {

  static targets = [
    "slot",
    "repeatOption",
    "repeatForm",
    "repeatPatternForm",
    "endDateRepeatForm",
    "endTimesRepeatForm",
    "daySlotForm"
  ]

  static values = { slotlUrl: String }

  initialize() { }

  connect() {
    // this.showRepeatForm()
  }

  preview(event) {
    const target = event.target
    const url = event.currentTarget.dataset.url

    // get(`${url}?format=turbo_stream`, {
    //   responseKind: 'turbo_stream'
    // })
      // fetch(`${url}`)
      //   .then((r) => r.html())
      //   .then((html) => {
      //     const fragment = document
      //       .createRange()
      //       .createContextualFragment(html);

      //     this.element.appendChild(fragment);
      //   });
  }

  hide(event) {
      currentIndex = this.togglerTargets.indexOf(event.currentTarget)

      slotTargets.forEach((element, index) => {
        if (index === currentIndex) {
          element.classList.add("hidden");
        }
      });
  }

  showEvents(event) {
    let day = event.target.dataset.day

    get(`/workspace/duty_roster/events?day=${day}`, {
      responseKind: 'turbo_stream'
    })
  }

  show(event) {
    const t = event.target.value

    if (this.hasOvernightSlotFormTarget) {
      if (t === 'day_slot') {
        this.overnightSlotFormTarget.classList.add('hidden')

      } else if (t === 'overnight_slot') {
        this.overnightSlotFormTarget.classList.remove('hidden')

      }
    }
  }

  showRepeatForm(event) {
    let el = event.currentTarget

    if (el?.value === 'true' || this.repeatSelected()) {
      this.repeatFormTarget.classList.remove('hidden')
    } else {
      if (!this.repeatFormTarget.classList.contains('hidden')) {
        this.repeatFormTarget.classList.add('hidden')
      }

      // this.hideRepeatPatternForm()
    }
  }

  repeatSelected() {
    if (this.hasRepeatOptionTarget) {
      if (this.repeatOptionTarget.checked && this.repeatOptionTarget.value == 'true') {
        return true
      }

      return false
    }
    return false
  }

  showRepeatPatternForm(event) {
    let el = event.currentTarget

    if (el.value.toLowerCase() === 'custom') {
      this.repeatPatternFormTarget.classList.remove('hidden')
    } else {
      this.hideRepeatPatternForm()
    }
  }

  showEndRepeatForm(event) {
    let el = event.currentTarget

    if (el.value.toLowerCase() === 'after') {
      this.endTimesRepeatFormTarget.classList.remove('hidden')
      this.hideEndDateForm()
    } else if (el.value.toLowerCase() === 'on date') {
      this.endDateRepeatFormTarget.classList.remove('hidden')
      this.hideEndTimesForm()
    } else if (el.value.toLowerCase() === 'never') {
      this.hideEndDateForm()
      this.hideEndTimesForm()
    }
  }

  hideEndDateForm() {
    if (!this.endDateRepeatFormTarget.classList.contains('hidden')) {
      this.endDateRepeatFormTarget.classList.add('hidden')
    }
  }

  hideEndTimesForm() {
    if (!this.endTimesRepeatFormTarget.classList.contains('hidden')) {
      this.endTimesRepeatFormTarget.classList.add('hidden')
    }
  }

  hideRepeatPatternForm() {
    if (!this.repeatPatternFormTarget.classList.contains('hidden')) {
      this.repeatPatternFormTarget.classList.add('hidden')
    }
  }

}
