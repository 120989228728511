import ApplicationController from './application_controller.js'
import { enter, leave } from "el-transition"


export default class extends ApplicationController {

  static targets = ["backdrop", "overlay", "panel", "button", "dialog"]

  show() {
    this.dialogTarget.classList.toggle("hidden")
    // if (this.hasBackdropTarget) enter(this.backdropTarget)
    // if (this.hasOverlayTarget) enter(this.overlayTarget)
    // if (this.hasPanelTarget) enter(this.panelTarget)
  }

  hide() {
    this.dialogTarget.classList.toggle("hidden")

    // Promise.all([
    //   leave(this.backdropTarget),
    //   leave(this.overlayTarget),
    //   leave(this.panelTarget)
    // ]).then(() => {
    //   this.dialogTarget.classList.add("hidden")
    // })
  }
}
