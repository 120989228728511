import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ['tab', 'panel']
  static classes = ['active', 'inactive']

  connect() {
    if (this.hasActiveClass && this.hasInactiveClass) {
      this.activeTabClasses = this.activeClasses
      this.inactiveTabClasses = this.inactiveClasses
    } else {
      this.activeTabClasses = ['text-primary-600', 'border-b-4', 'border-primary', 'hover:border-primary-200', 'hover:text-primary-900', 'py-4', 'px-1']
      this.inactiveTabClasses = ['text-gray-400',  'hover:border-primary-200', 'hover:text-primary-900', 'py-4', 'px-1']
    }
    if (this.anchor) this.index = this.tabTargets.findIndex((tab) => tab.id === this.anchor)
    this.showTab()
  }

  change(event) {
    event.preventDefault()

    // If target specifies an index, use that
    if (event.currentTarget.dataset.index) {
      this.index = event.currentTarget.dataset.index

    // If target specifies an id, use that
    } else if (event.currentTarget.dataset.id) {
      this.index = this.tabTargets.findIndex((tab) => tab.id == event.currentTarget.dataset.id)

    // Otherwise, use the index of the current target
    } else {
      this.index = this.tabTargets.indexOf(event.currentTarget)
    }

    window.dispatchEvent(new CustomEvent('tsc:tab-change'))
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]

      if (index === this.index) {
        panel.classList.remove('hidden')
        tab.classList.remove(...this.inactiveTabClasses)
        tab.classList.add(...this.activeTabClasses)
        tab.setAttribute('aria-selected', true)
        tab.setAttribute('tabindex', 0)

        // Update URL with the tab ID if it has one
        // This will be automatically selected on page load
        if (tab.id) {
          location.hash = tab.id
        }
      } else {
        panel.classList.add('hidden')
        tab.classList.remove(...this.activeTabClasses)
        tab.classList.add(...this.inactiveTabClasses)
        tab.setAttribute('aria-selected', false)
        tab.setAttribute('tabindex', -1)
      }
    })
  }

  get index() {
    return parseInt(this.data.get('index') || 0)
  }

  set index(value) {
    this.data.set('index', (value >= 0 ? value : 0))
    this.showTab()
  }

  get anchor() {
    return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
  }
}
