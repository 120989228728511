import ApplicationController from './application_controller'

export default class extends ApplicationController  {
    static targets =["label", "flymenu"]

    showFlyMenu(event) {
        event.preventDefault()
        let element = event.currentTarget;
        this.flymenuTarget.classList.toggle('hidden')
        element.ariaExpanded = true

    }
}
