import ApplicationController from './application_controller'
import { post } from '@rails/request.js'
import SignaturePad from 'signature_pad'

export default class extends ApplicationController {

  static targets = [ "canvas", "clearButton", "submitButton"]
  static values = {
    url: String,
    tabletUrl: String
  }

  connect() {
    this.signature = new SignaturePad(this.canvasTarget)
  }

  async submit() {
    if (this.signature.isEmpty()) {
        alert("Please provide a signature first.");
    } else {
      // submit the form
      await post(`${this.urlValue}`, {
        body: JSON.stringify({ visitor: { signature: this.signature.toDataURL() } }),
        responseKind: "turbo_stream"
      })

      Turbo.visit(this.taletUrlValue)
    }
  }

  clear() {
    this.signature.clear()
  }
}