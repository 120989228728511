import ApplicationController from "./application_controller";
import { get } from "@rails/request.js";

export default class extends ApplicationController {
  static targets = ["unitSelect", "vacantOnly", "studentNumber", "errors"];

  validateStudentNumber() {
    if (this.hasStudentNumberTarget && this.hasErrorsTarget) {
      const student_number = this.studentNumberTarget.value;
      const valid = student_number.match(/^[A-Za-z]{6}\d{3}$/g);

      if (valid != null) {
        this.errorsTarget.classList.add("text-green-500");
        this.errorsTarget.textContent = "Student number is valid";
        return true;
      } else {
        this.errorsTarget.classList.add("text-danger-400");
        this.errorsTarget.classList.remove("hidden");
        this.errorsTarget.textContent = "Student number is invalid";
        return false;
      }
    }
  }

  change(event) {
    let unit = event.target.selectedOptions[0].value;
    let target = this.unitSelectTarget.id;
    let vacant = this.vacantOnlyTarget.value;

    get(`/units/${unit}/list_bedrooms?target=${target}&vacant=${vacant}`, {
      responseKind: "turbo_stream",
    });
  }
}
