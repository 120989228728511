import ApplicationController from "./application_controller";
import { get } from "@rails/request.js";
import { useDispatch } from "stimulus-use";

export default class extends ApplicationController {
  static targets = [
    "slide",
    "form",
    "bookable",
    "errors",
    "select",
    "slot",
    "bookedSlot",
    "booking",
    "submit",
    "washers",
    "dryers",
  ];

  connect() {
    useDispatch(this);
    this.dispatch("connect", {
      screen: {
        page_title: "New Laundry Slot",
        page_path: "serobe.co.za/users/bookings/new",
      },
    });
  }

  submit(url, method, target) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        type: method,
        url: url,
        data: new FormData(target),
        success: (data) => {
          resolve(data);
        },
        error: (data) => {
          reject(data);
        },
      });
    });
  }

  showSubmitButton() {
    if (this.hasSubmitTarget && this.hasWashersTarget && this.hasDryersTarget) {
      dryers = this.dryersTarget.value;
      washers = this.washersTarget.value;

      if (dryers.length !== 0 && washers.length !== 0) {
        this.submitTarget.classList.remove("hidden");
      } else {
        this.submitTarget.classList.add("hidden");
      }
    }
  }

  selectedSlot(event) {
    this.dispatch("selectedSlot", {
      event: {
        name: "select_slot",
        object: { page_path: "serobe.co.za/users/bookings/new" },
      },
    });

    const washers = event.currentTarget.dataset.washers;
    const dryers = event.currentTarget.dataset.dryers;
    const booking = event.currentTarget.dataset.booking;
    let day = event.currentTarget.dataset.slot;
    let bookable = event.currentTarget.dataset.bookable;
    let selectedIndex = this.slotTargets.indexOf(event.currentTarget);

    this.slotTargets.forEach((slot, index) => {
      if (index === selectedIndex) {
        event.currentTarget.classList.add("bg-secondary");
        event.currentTarget.classList.add("text-white");
        event.currentTarget.classList.remove("text-secondary");
      } else {
        slot.classList.remove("bg-secondary");
        slot.classList.remove("text-white");
        slot.classList.add("text-secondary");
      }
    });
    this.bookedSlotTarget.value = event.currentTarget.dataset.slot;

    get(
      `/users/laundry/${day}/book?slot=${this.bookedSlotTarget.value}&facility_id=${bookable}&max_dryers=${dryers}&max_washers=${washers}&booking_id=${booking}`,
      {
        responseKind: "turbo_stream",
      }
    );
  }
  getSlots(event) {
    let day = event.currentTarget.dataset.day;
    let target = event.currentTarget.dataset.slots;
    const bookable = event.currentTarget.dataset.bookable;
    const booking = event.currentTarget.dataset.booking;

    get(
      `/users/laundry/${day}/available_slots?target=${target}&facility_id=${bookable}&booking=${booking}`,
      {
        responseKind: "turbo_stream",
      }
    );
  }

  setChecked(event) {
    this.index = this.selectTargets.indexOf(event.currentTarget);
    this.selectTargets.forEach((slide, index) => {
      if (index !== this.index) {
        this.selectTargets[index].classList.remove("clicked");
        this.selectTargets[index].checked = false;
      } else {
        this.selectTargets[index].classList.add("clicked");
        this.selectTargets[index].classList.add("bg-red");
        this.selectTargets[index].checked = true;
      }
    });
  }

  select(event) {
    this.index = this.slideTargets.indexOf(event.currentTarget);
    this.slideTargets.forEach((slide, index) => {
      if (index !== this.index) {
        this.slideTargets[index].classList.remove("clicked");
        this.slideTargets[index].classList.remove("bg-aeroBlue");
      } else {
        this.slideTargets[index].classList.add("clicked");
        this.slideTargets[index].classList.add("bg-aeroBlue");
      }
    });
  }

  async save(event) {
    event.preventDefault();
    if (this.hasSlotTarget) {
      this.slotTarget.value =
        document.getElementsByClassName(
          "clicked"
        )[0].firstElementChild.firstElementChild.innerHTML;
    }
    this.index = this.formTargets.indexOf(event.currentTarget);
    // If target specifies an index, use that
    if (event.currentTarget.dataset.id == "submit") {
      if (this.index === 0) {
        var errors = "";
        await this.submit(
          `/users/bookings?facility_id=${this.bookableTarget.value}`,
          "POST",
          this.formTargets[this.index]
        ).catch(function (response) {
          errors = response;
        });
        if (this.hasErrorsTarget) {
          this.errorsTarget = errors;
        }
      }
    }
    window.dispatchEvent(new CustomEvent("tsc:tab-change"));
  }

  async update(event) {
    event.preventDefault();
    if (this.hasSlotTarget) {
      this.slotTarget.value =
        document.getElementsByClassName(
          "clicked"
        )[0].firstElementChild.firstElementChild.innerHTML;
    }
    this.index = this.formTargets.indexOf(event.currentTarget);
    // If target specifies an index, use that
    if (event.currentTarget.dataset.id == "submit" && this.hasBookingTarget) {
      if (this.index === 0 && this.slotTarget.value !== undefined) {
        var errors = "";
        await this.submit(
          `/users/bookings/${this.bookingTarget.value}`,
          "PUT",
          this.formTargets[this.index]
        ).catch(function (response) {
          errors = response;
        });
        if (this.hasErrorsTarget) {
          this.errorsTarget = errors;
        }
      }
    }
    window.dispatchEvent(new CustomEvent("tsc:tab-change"));
  }
  get index() {
    return parseInt(this.data.get("index") || 0);
  }

  set index(value) {
    this.data.set("index", value >= 0 ? value : 0);
  }

  get anchor() {
    return document.URL.split("#").length > 1
      ? document.URL.split("#")[1]
      : null;
  }
}
