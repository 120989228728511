import  ApplicationController  from './application_controller'

export default class extends ApplicationController {
  static targets = [ "star", "score"]

  initialize() {}

  connect() {}

  highlightStar(event) {
    this.index = this.starTargets.indexOf(event.currentTarget)
    this.starTargets.forEach((star, index) => {
      if (index <= this.index) {
        star.classList.add('fill-current')
      }
      if (index > this.index) {
        star.classList.remove('fill-current')
      }
    })
    this.scoreTarget.value = (this.index +1)
  }
}
