import ChartsController from '../charts_controller'

export default class extends ChartsController {

  static values = { chartType: String, series: Array, labels: Array }

  initialize() {
    this.options = {
      chart: {
        type: 'donut',
        plotOptions: {
          pie: {
            donut: {
              labels: { show: true, name: { show: true }, value: { show: true }, total: { show: true, label: 'Total'} }
            }
          }
        }
      },
      legend: {
        position: 'top'
      },
      series: this.seriesValue,
      labels: this.labelsValue
    }
  }

  connect() { this.render(this.options) }
}
