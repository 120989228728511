import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class extends Controller {
  static targets = ["input"];

  static values = {
    choices: Array,
    enableSearch: Boolean,
    placeholderValue: String,
  };

  connect() {
    if (this.hasChoicesValue && this.hasInputTarget) {
      const choices = new Choices(this.inputTarget, {
        choices: this.choicesValue,
        position: "auto",
        searchEnabled: this.enableSearchValue,
        placeholder: true,
        placeholderValue: this.placeholderValue,
        searchPlaceholderValue: this.placeholderValue,
        itemSelectText: "Click to select",
      });
    }
  }
}
