const radio = (controller) => {
  Object.assign(controller, {
    connect() {},

    select(event) {
      if (this.hasRadioButtonTarget) {
        if (event.currentTarget.checked) {
          this.radioButtonTarget.classList.remove('border-transparent');
          this.radioButtonTarget.classList.add('border-blueCrayola');
          this.radioButtonTarget.classList.add('bg-blueCrayola');
        } else {
          this.radioButtonTarget.classList.add('border-transparent');
          this.radioButtonTarget.classList.remove('border-blueCrayola');
          this.radioButtonTarget.classList.remove('bg-blueCrayola');
        }
      }
    },
  });
};

export default radio
