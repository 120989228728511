import  ApplicationController  from './application_controller'

export default class extends ApplicationController {

  static targets = [
    "secondaryEmailForm"
  ]

  initialize() { this.secondaryEmailFormTarget.classList.remove('hidden') }

  show(event) {
    const t = event.target.value

    if (this.hasSecondaryEmailFormTarget) {
      if (t === 'has_secondary_email') {
        this.secondaryEmailFormTarget.classList.remove('hidden')

      } else if (t === 'no_secondary_email') {
        this.secondaryEmailFormTarget.classList.add('hidden')

      }
    }
  }

}

