import  ApplicationController  from './application_controller'

export default class extends ApplicationController {

  static targets = [
    "menu",
    "dropdown"
  ]

  initialize() {
  }

  connect() {
    this.hide()
  }

  close() {
    this.hide()
    setTimeout(() => {
      this.element.remove()
    }, 1100)

  }

  hideDropdown(event) {
    if (this.hasDropdownTarget) {
      this.dropdownTarget.classList.add('hidden')
      this.dropdownTarget.classList.remove("opacity-100", "scale-100", "duration-150", "ease-out");
    }
  }

  showDropdown(event) {
    if (this.hasDropdownTarget) {
      this.dropdownTarget.classList.remove('hidden')
      this.dropdownTarget.classList.add("opacity-100", "scale-100", "duration-150", "ease-out");
    }
  }

  show(event) {
    if (this.hasMenuTarget) {
      this.menuTarget.classList.remove("hidden");
      setTimeout((() => {
        this.menuTarget.classList.remove("opacity-0", "scale-95", "duration-100", "ease-in");
        this.menuTarget.classList.add("opacity-100", "scale-100", "duration-150", "ease-out");
      }), 10)
    }
  }

  hide(event) {
    if (this.hasMenuTarget) {
      this.menuTarget.classList.add("opacity-0", "scale-95", "duration-100", "ease-in");
      this.menuTarget.classList.remove("opacity-100", "scale-100", "duration-150", "ease-out");
      setTimeout((() => {
        this.menuTarget.classList.add("hidden");
      }), 150)
    }
  }
}
