import ApplicationController  from './application_controller'

export default class extends ApplicationController {
  static targets = ["modal", "toggler"]

  initialize() {
  }


  close(event) {
    event.preventDefault();
    this.hide();
  }

  toggle(event) {
    let element = event.currentTarget
    let modalIndex = this.togglerTargets.indexOf(event.currentTarget)
    this.togglerTargets.forEach((toggler, index) => {
      const modal = this.modalTargets[index]

      if (index === modalIndex) {
        if ( modal.classList.contains('hidden')) {
          element.value = 'Hide'
          this.show(event)
        } else {
          element.value = 'Show'
          this.hide(event)
        }
      }
    })

  }
  show(event) {
    event.preventDefault()
    this.index = this.togglerTargets.indexOf(event.currentTarget)
    this.togglerTargets.forEach((toggler, index) => {
      const modal = this.modalTargets[index]

      if (index === this.index) {
        modal.classList.remove('hidden')
        // Update URL with the tab ID if it has one
        // This will be automatically selected on page load
        if (toggler.id) {
          location.hash = toggler.id
        }
      } else {
        modal.classList.add('hidden')
      }
    })
  }

  hide(event) {
    event.preventDefault()
    this.index = this.togglerTargets.indexOf(event.currentTarget)

    this.togglerTargets.forEach((toggler, index) => {
      const modal = this.modalTargets[index]

      if (index === this.index) {
        modal.classList.add('hidden')
        // Update URL with the tab ID if it has one
        // This will be automatically selected on page load
        if (toggler.id) {
          location.hash = toggler.id
        }
      } else {
        modal.classList.add('hidden')
      }
    })
  }
}
