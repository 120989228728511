import ApplicationController from './application_controller'
import Sortable from 'sortablejs';

export default class extends ApplicationController {
  static targets = ["list"]

  connect() {
    Sortable.create(this.listTarget, {
        animation: 100,
        easing: "cubic-bezier(1, 0, 0, 1)",
        sort: true,
    });
  }
}