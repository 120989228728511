const collapse = (controller) => {
  Object.assign(controller, {
    connect() { },

    change() {
      if (this.hasTogglerTarget && this.hasPanelTarget) {
        console.log(this.panelTarget.classList)
        if (this.panelTarget.classList.contains('hidden')) {
          this.show()
        } else {
          this.hide()
        }
      }
    },
    hide() {
      this.panelTarget.classList.add('hidden');
    },
    show() {
      this.panelTarget.classList.remove('hidden');
    },
  });
};

export default collapse
