import ChartsController from '../charts_controller'

export default class extends ChartsController {

  static values = { chartType: String, series: Array, labels: Array }

  initialize() {
    this.options = {
      chart: {
        height: 380,
        width: "100%",
        type: "area",
        animations: {
          initialAnimation: {
            enabled: false
          }
        },
        stacked: true
      },
      series: this.seriesValue,
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: "datetime"
      }
    }
  }

  connect() { this.render(this.options) }
}
