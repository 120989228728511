import { Controller } from '@hotwired/stimulus'

class ApplicationController extends Controller {

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    pluralize(word, count, suffix) {
      if (count > 1 || count == 0) {
        return `${count} ${word}${suffix}`
      } else {
        return `${count} ${word}`
      }
    }
}

export default ApplicationController
