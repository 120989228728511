import  ApplicationController  from './application_controller'

export default class extends ApplicationController {

  static targets = ["chat"]

  connect() {
    let scrollElement = this.chatTarget;
    window.scrollTo(0, document.body.scrollHeight);

    // scrollElement.scrollTo({
    //     top: 900,
    //     bottom: 0,
    //     behaviour: 'smooth'
    // })
  }

}
