
import ApplicationController  from './application_controller'

export default class extends ApplicationController {
  static targets = [ "spinner" ]

  connect() {
  }

  start() {
    this.spinnerTarget.classList.remove('hidden')
  }

  stop() {
    this.spinnerTarget.classList.add('hidden')
  }
}