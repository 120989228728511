import ApplicationController  from './application_controller'
import radio from '../mixins/radio'
import spinner from '../mixins/spinner'
import studentNumberValidator from '../mixins/student_number_validator'
import { put } from '@rails/request.js';

export default class extends ApplicationController {
  static targets = [
    "actions",
    "errors",
    "studentNumber",
    "form",
    "option",
    "email",
    "checkbox",
    "input",
    "radioButton",
    "selectedCount",
    "submit"
  ]

  static values = {
    domain: String
  }

  initialize() { this.count = 0; }

  connect() { studentNumberValidator(this); this.toggle(); spinner(this);  }

  validate() { studentNumberValidator(this) }

  submit(event) {
    this.formTarget.submit()
  }

  assignEmail(event) {
    this.emailTarget.value = event.currentTarget.value+this.domainValue
  }

  submitted(url, method) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        type: method,
        url: url,
        success: data => {
          resolve(data)
        },
      });
    });
  }

  select(event) {
    radio(this)
  }

  async toggle(event) {
    if (this.hasOptionTarget && this.hasInputTarget) {
      var selection = document.getElementById("template_option").value
      if (selection === 'New Message') {
        this.inputTarget.classList.remove('hidden');
      } else {
        this.inputTarget.classList.add('hidden');
      }
    }
  }

  async approveAll(event) {
    event.preventDefault()
    this.checkboxTargets.forEach((el) => {
      if (el.checked) {
        put(`/workspace/bookings/${el.value}`, {
          body: JSON.stringify({ status: 'approved'}),
          responseKind: 'html'
        })
      }
    })
  }
  check(event) {
    const el = event.currentTarget

    if (el.checked) {
      this.count += 1
      this.actionsTarget.classList.remove('hidden')
      this.selectedCountTarget.innerHTML = `${this.count} selected`
    } else {
      this.count -= 1
      this.selectedCountTarget.innerHTML = `${this.count} selected`
      if (!(this.anyChecked)) {
        this.actionsTarget.classList.add('hidden')
      } 
    }
  }
  anyChecked() {
    this.checkboxTargets.forEach((el) => {
      if (el.checked) {
        return true
      }
    })
    return false
  }

  checkAll(event) {
    const el = event.currentTarget

    if (el.checked) {
      this.checkAllCheckboxes('check')
      this.count = this.checkboxTargets.length
      this.selectedCountTarget.innerHTML = `${this.count} selected`
      this.actionsTarget.classList.remove('hidden')
    } else {
      this.checkAllCheckboxes('uncheck')
      this.count = 0
      this.selectedCountTarget.innerHTML = ''
      this.actionsTarget.classList.add('hidden')
    }
  }

  checkAllCheckboxes(val) {
    this.checkboxTargets.forEach((el) => {
      if (val === 'check') {
        el.checked = true
      } else {
        el.checked = false
      }
    })
  }

}
