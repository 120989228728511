// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
require("@rails/activestorage").start()
import * as ActionCable from '@rails/actioncable'
ActionCable.logger.enabled = true
window.Cable = ActionCable
window.App = {}
App.cable = ActionCable.createConsumer()
// import TC from "@rolemodel/turbo-confirm"

// TC.start()
/* eslint no-console:0 */
import './controllers'

import gtag from './src/analytics'
import './src/custom_trix'
// import './src/direct_uploads'
