import { Controller } from '@hotwired/stimulus'
import { enter, leave } from "el-transition"
import { get, post} from '@rails/request.js'

export default class extends Controller {
  static values = { 
    back: String,
    steps: Array,
    nextUrls: Array,
    step: String
  }

  static targets = ["progress"]


  connect() {
    this.step = this.hasStepValue ? this.stepValue : this.stepsValue[0]
    this.stepIndex = this.stepsValue.indexOf(this.step)

    enter(this.element)
    const lastURL = window.localStorage.getItem('lastURL')

    if (lastURL !== window.location.href) {
      window.localStorage.setItem('lastURL', window.location.href)
    }
  }

  close() {
    const url = window.localStorage.getItem('lastURL') || history.back() 
    Turbo.visit(url, { action: 'replace' })
    leave(this.element)
  }

  back(event) {
    event.preventDefault();

    console.log("before", this.stepIndex, this.step)
    this.stepIndex = (this.stepIndex - 1)
    console.log(this.stepIndex, this.step)

    if (this.stepIndex < 0) {
      this.stepIndex = 0
    } else {
      this.stepIndex
    }

    this.step = this.stepsValue[this.stepIndex]
    Turbo.visit(history.back(), { frame: '_top' })

    this.progressTarget.innerHTML = `Step ${this.stepIndex} of ${this.stepsValue.length}`
  }

  async next(event) {
    event.preventDefault();

    this.stepIndex = (this.stepIndex + 1) 
    this.step = this.stepsValue[this.stepIndex % (this.stepsValue.length)]

    this.progressTarget.innerHTML = `Step ${this.stepIndex} of ${this.stepsValue.length}`

    await get(event.currentTarget.href, {
      reponseKind: 'html'
    })
  }
}

