import  ApplicationController  from './application_controller'

export default class extends ApplicationController {

  static targets = [
    "invitationOption",
    "file",
    "number",
    "daySlotForm"
  ]

  static values = { slotlUrl: String }

  initialize() { }

  showHiddenForm(event) {
    let element = event.currentTarget

    if (element.value === 'single') {
      this.numberTarget.classList.remove('hidden')
      this.fileTarget.classList.add('hidden')
    } else if (element.value === 'many') {
      this.numberTarget.classList.add('hidden')
      this.fileTarget.classList.remove('hidden')
    }
  }

  studentNumberOptionSelected() {
    if (this.hasInvitationOptionTarget()) {
      if (this.invitationOptionTarget.checked && this.invitationOptionTarget.value === 9) {
        return true
      }
    }
  }
}